import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

export const PrivacyPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="row pt-5">
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <PageContent
            className="content pt-2 pb-4 text-line-height"
            content={content}
          />
        </div>
      </div>
      <ThreeLinkBlock />
    </>
  );
};

const PrivacyPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PrivacyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

export default PrivacyPage;

export const privacyPageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
